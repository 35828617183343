import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchItems(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/content/category-list', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/content/category-get?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/content/category-save', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        editItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .put('/content/category-save', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/content/category-delete?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        imageItems(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/content/category-image-list?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addImages(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/content/category-image-save', dataInfo, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeImage(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/content/category-image-delete?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
